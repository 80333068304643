<template>
  <el-dialog
      :append-to-body="true"
      :visible="dialogShow"
      title="编辑"
      width="460px"
      :before-close="beforeCancel"
      class="edit-serve-dialog"
  >
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-position="left"
    >
      <el-form-item
        label="服务器名:"
        prop="serveName"
      >
        <div class="serve-name">{{formData.serverName}}</div>
      </el-form-item>
      <el-form-item
          label="服务器代码:"
          prop="fileList"
      >
        <div class="serveCode">{{formData.serverCode}}</div>
      </el-form-item>
      <el-form-item
          label="平台名称:"
          prop="plantName"
      >
        <el-input
            disabled
            v-if="formData.platformName"
            style="width: 200px"
            placeholder="请输入"
            v-model="form.plantName"
        >
        </el-input>
        <el-select
            v-if="!formData.platformName"
            style="width: 200px;"
            class="filter-item"
            v-model="form.plantName"
            placeholder="平台名称"
            filterable
            clearable
            remote
            :remote-method="remoteSignalMethod"
            :loading="selectLoading"
        >
          <el-option
              v-for="(item, index) in platNameList"
              :key="index"
              :label="item"
              :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="状态:"
        prop="status"
      >
        <el-radio v-model="form.status" :label="1">有效</el-radio>
        <el-radio v-model="form.status" :label="0">无效</el-radio>
      </el-form-item>
      <el-form-item>
        <div class="dialog-footer">
          <el-button type="text" @click="cancel">取消</el-button>
          <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { queryPlatformName, editServe } from "@/api/system/serveManage";

let orginFormData = {
  id: 1,
  serveName: '',
  serveCode: '',
  plantName: '',
  status: 1,
}
export default {
  name: 'editDialog',
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    formData:{
      type: Object,
      default(){
        return orginFormData;
      }
    }
  },
  data() {
    return {
      loading: false,
      form: orginFormData,
      rules:{
        plantName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      selectLoading: false,
      platNameList: [],
    }
  },
  watch:{
    formData:{
      handler(newValue,oldValue){
        /*this.form.serveName = newValue.serveName;
        this.form.serveCode = newValue.serveCode;*/
        this.form.plantName = newValue.platformName;
        this.form.status = newValue.status;
      },
      deep: true,
    }
  },
  computed: {
  },
  methods: {
    doSubmit(){
      this.$refs.form.validate((valid) => {
        if(valid){
          // todo
          editServe({
            id: this.formData.id,
            platformName: this.form.plantName,
            status: this.form.status,
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '编辑成功'
            })
            this.$emit('edit-success');
          })
        }
      })
    },

    close(){
      this.$emit('close');
    },
    beforeCancel() {
      this.close();
    },
    cancel(){
      this.close();
    },
    remoteSignalMethod(query){
      if (query !== '') {
        this.loading = true;
        queryPlatformName({
          platformName: query,
        }).then((res) => {
          this.loading = false;
          this.platNameList = res;
        });
      } else {
        this.platNameList = [];
      }
    },
  }
}
</script>

<style lang="less" scoped>
.edit-serve-dialog {
  /deep/ .el-dialog__title {
    font-weight: bold;
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;

}
</style>
