<template>
  <div class="app-container">
    <eHeader
        ref="header"
        @query="queryHandle"
        @upload="uploadHandle"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column
          prop="platformName"
          label="平台名称"
      >
        <template
            slot-scope="scope"
        >
          <span>{{scope.row.platformName || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="apiHostName"
          label="主机服务器"
      />
      <el-table-column prop="value" label="链接类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="serverName"
          label="服务器名"
      />
      <el-table-column
          prop="serverHost"
          label="服务器地址"
      />
      <el-table-column
          prop="serverCode"
          label="服务器代码"
      />
      <el-table-column
          prop="filePath"
          label="配置文件路径"
      />
      <el-table-column
          label="状态"
      >
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
      >
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="updateTime"
          label="更新时间"
      >
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.updateTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px" align="left">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="success"
              @click="editHandle(scope.row)"
          >编辑</el-button>
          <el-button
              v-if="scope.row.connectionType != 1 && getLoadBtnShow(scope.row)"
              size="mini"
              type="success"
              ref="scope.row.id"
              @click="loadHandle(scope.row,scope.$index)"
              :loading="scope.row.loading"
          >加载</el-button>
          <el-button
              v-if="scope.row.connectionType != 1 && getAgainLoadBtnShow(scope.row)"
              size="mini"
              type="success"
              :loading="scope.row.againLoading"
              @click="againHandle(scope.row,scope.$index)"
          >重新加载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
        :total="total"
        style="margin-top: 8px;"
        layout="total, prev, pager, next, sizes"
        @size-change="sizeChange"
        @current-change="pageChange"/>
    <upload-dialog
        :dialog-show="uploadDialogShow"
        @close="closeHandle"
        @add-success="addSuccess"
    ></upload-dialog>
    <edit-dialog
        :dialog-show="editDialogShow"
        @close="editDialogShow = false"
        @edit-success="editSuccess"
        :form-data="currentRow"
    ></edit-dialog>
  </div>
</template>

<script>
import { loadServer } from '@/api/system/serveManage'
import editDialog from "@/components/system/serverManage/editDialog";
import uploadDialog from "@/components/system/serverManage/uploadDialog";
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils'
import eHeader from '@/components/system/serverManage/header'

export default {
  name:'serverManage',
  components: {
    eHeader,
    uploadDialog,
    editDialog,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      editDialogShow: false,
      currentRow: {},
      uploadDialogShow: false,
      // 加载
      loadLoading: false,
      //
      againLoading: false,
    }
  },
  mounted() {
    // 多个标签页时,切换标签时没有缓存 todo
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    parseTime,
    checkPermission,
    closeHandle(){
      this.uploadDialogShow = false;
    },
    beforeInit() {
      this.url = 'community/crm/queryServers';
      let header = this.$refs.header;
      const sort = 'id,desc'
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort
      }
      if(header.status !==''){
        this.params.status = header.status;
      }
      if(header.platName){
        this.params.platformName = header.platName;
      }
      if(header.serveName){
        this.params.serverName = header.serveName;
      }
      return true
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
    getType(row){
      if(row.connectionType == 1){
        return 'manager';
      }else if(row.connectionType == 2) {
        return 'client'
      }
    },
    getStatus(row){
      if(row.status === 0){
        return '失效';
      }else if(row.status == 1){
        return '有效';
      }else if(row.status === 2){
        return '未加载';
      }
    },
    getLoadBtnShow(row){
      if(row.status == 2){
        return true;
      }else {
        return false;
      }
    },
    loadHandle(row,index){
      // 加载 ajax
      this.$set(this.data[index],'loading',true);
      loadServer({id: row.id}).then((res) => {
        this.$message({
          type: 'success',
          message: '加载成功'
        })
        this.$set(this.data[index],'loading',false);
        this.init();
      }).catch((err) => {
        this.$set(this.data[index],'loading',false);
      })
    },
    getAgainLoadBtnShow(row){
      // 重新加载
      if(row.status == 1){
        return true;
      }else {
        return false;
      }
    },
    againHandle(row,index){
      // 重新加载
      this.$set(this.data[index],'againLoading',true);
      loadServer({id: row.id}).then((res) => {
        this.$message({
          type: 'success',
          message: '重新加载成功'
        })
        this.$set(this.data[index],'againLoading',false);
        this.init();
      }).catch((err) => {
        this.$set(this.data[index],'againLoading',false);
      });
    },
    addSuccess(){
      // 添加成功
      this.uploadDialogShow = false;
      this.page = 0;
      this.init();
    },
    editHandle(row){
      this.editDialogShow = true;
      this.currentRow = row;
    },
    editSuccess(){
      this.editDialogShow = false;
      this.page = 0;
      this.init();
    },
    uploadHandle(){
      // 上传
      this.uploadDialogShow = true;
    }
  }
}
</script>

<style scoped>

</style>
