import request from '@/utils/request'

export function queryHostList(data, tableName) {
  return request({
    url: 'community/crm/validApiHost',
    params: data,
    method: 'get'
  })
}

/*
* hostId: 主机id,
* file: ini文件
* */
export function uploadIni(data, tableName) {
  return request({
    url: 'community/crm/uploadServerIni',
    data,
    method: 'post'
  })
}

export function queryPlatformName(data, tableName) {
  return request({
    url: 'community/crm/getDistinctSrPlatformInfo',
    params: data,
    method: 'get'
  })
}

export function editServe(data, tableName) {
  return request({
    url: 'community/crm/updateTradeServer',
    data,
    method: 'put'
  })
}

// 重新加载或者加载服务器
export function loadServer(data, tableName) {
  return request({
    url: 'community/crm/loadNewServer',
    params: data,
    method: 'get'
  })
}
