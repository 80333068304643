<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="status"
            clearable
            placeholder="状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in statusOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
<!--    需要改成远程搜索 todo-->
    <el-select
        style="width: 200px;"
        class="filter-item"
        v-model="platName"
        placeholder="平台名称"
        filterable
        clearable
        remote
        :remote-method="remoteSignalMethod"
        :loading="loading"
    >
      <el-option
          v-for="(item, index) in platNameList"
          :key="index"
          :label="item"
          :value="item"
      ></el-option>
    </el-select>
    <el-input
        style="width: 200px;"
        class="filter-item"
        v-model="serveName"
        clearable
        placeholder="服务器名称"
    ></el-input>
    <el-button
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-search"
        @click="toQuery"
    >搜索</el-button>
    <!-- 新增 -->
    <el-button
        v-if="checkPermission(['ADMIN'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="uploadHandle"
    >上传ini文件</el-button>
  </div>
</template>

<script>
import { queryPlatformName } from '@/api/system/serveManage'
import checkPermission from '@/utils/permission'
export default {
  data() {
    return {
      statusOptions: [
        { key: 0, display_name: '失效' },
        { key: 1, display_name: '有效' },
        { key: 2, display_name: '未加载' },
      ],
      loading: false,
      platNameList: [],
      status: '',
      platName: '',
      serveName: '',
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('query');
    },
    uploadHandle(){
      //
      this.$emit('upload');
    },
    remoteSignalMethod(query){
      if (query !== '') {
        this.loading = true;
        queryPlatformName({
          platformName: query,
        }).then((res) => {
          this.loading = false;
          this.platNameList = res;
        });
      } else {
        this.platNameList = [];
      }
    },
  }
}
</script>
