<template>
  <el-dialog
      :append-to-body="true"
      :visible="dialogShow"
      title="上传"
      width="460px"
      :before-close="beforeCancel"
  >
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        size="small"
        label-position="top"
    >
      <el-form-item
        label="主机服务器"
        prop="masterServe"
      >
        <el-select
          placeholder="请选择"
          v-model="form.masterServe"
        >
          <el-option
            v-for="(item,index) in masterServeList"
            :key="index"
            :label="item.hostName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          label="ini文件:"
          prop="fileList"
      >
        <el-upload
            ref="upload"
            class="upload-demo"
            drag
            :action="filesUploadApi"
            :on-success="handleSuccess"
            :before-upload="beforeAvatarUpload"
            :file-list="form.fileList"
        >
          <!--:file-list="form.fileList"是否支持多个文件上传 todo-->
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
<!--          <div class="el-upload__tip" slot="tip">
            <p>只能.xls,.xlsx文件</p>
          </div>-->
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="dialog-footer">
          <el-button type="text" @click="cancel">取消</el-button>
          <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { queryHostList, uploadIni, } from '@/api/system/serveManage';
import { mapGetters } from 'vuex'
export default {
  name: 'uploadDialog',
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      form:{
        masterServe: '',
        fileList: [],
      },
      rules:{
        fileList: [
          {required: true, message: '请上传', trigger: 'change'},
        ],
      },
      masterServeList: [
        { label: '1主机服务器1',value: 1},
        { label: '2主机服务器2',value: 2},
      ],
      base64Data: '',
      fileName: '',// 文件名
    }
  },
  created(){
    this.getHostList();
  },
  computed: {
    ...mapGetters(['filesUploadApi']),
  },
  methods: {
    reset(){
      this.form.masterServe = '';
      this.form.fileList = [];
    },
    getHostList(){
      queryHostList().then((res) => {
        this.masterServeList = res;
      })
    },
    doSubmit(){
      this.$refs.form.validate((valid) => {
        if(valid){
          //上传ini文件 todo
          this.loading = true;
          uploadIni({
            hostId: this.form.masterServe,
            fileName: this.fileName,
            url: this.form.fileList[0].url,
          }).then((res) => {
            this.loading = false;

            this.$message({
              type: 'success',
              message: '上传成功'
            })
            this.$emit('add-success');
            this.reset();
          })
        }
      })
    },

    close(){
      this.$emit('close');
    },
    beforeCancel() {
      this.close();
    },
    cancel(){
      this.close();
    },
    beforeAvatarUpload(file) {
      console.log(file)
      // 是否限制文件类型
      const isIniFile = file.name.includes('.ini');
      this.fileName = file.name;
      if (!isIniFile) {
        // 需要验证测试.文件
        this.$message.error('请上传ini文件');
      }
      return isIniFile;
    },
    handleSuccess(response){
      // 改为上传fileName
      response.data.content[0].name = response.data.content[0].fileName;
      this.form.fileList = [response.data.content[0]];
      this.$refs.upload.clearFiles();
    },
    handleRemove(file){
      // 只能传单个
      this.form.fileList = [];
    },
  }
}
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;

}
</style>
